@use "../theme/is-theme" as theme;
@use "../variables.scss" as var;

@use '@angular/material' as mat;

.mat-mdc-card {
    .mat-mdc-card-title{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-family: "rubik";
        .mat-icon {
            margin-right: 10px;
        }
    }
}

.mdc-card.mdc-card--blue-shadow {
  @include mat.elevation(4, theme.$primary, 0.1);
}