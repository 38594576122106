@use "variables.scss" as var;



.u__grid {
    display: grid;
    gap: calc(var.$grid-gap / 2);
    
    &--1-1 {
       
        grid-template-columns: 1fr 1fr;
    }
    &--1-3 {
        display: grid;
        grid-template-columns: 1fr 3fr;
       
    }
    &--3-1 {
        display: grid;
        grid-template-columns: 3fr 1fr;
       
    }
    &--2-1 {
        display: grid;
        grid-template-columns: 2fr 1fr;
       
    }
    &--1-1-1 {
        grid-template-columns: 1fr 1fr 1fr;
    }
    &--1-1-1-1 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &--1-1-4 {
        grid-template-columns: 1fr 1fr 4fr;
    }
    &--auto-1 {
        display: grid;
        grid-template-columns: auto 1fr;
       
    }
    &--vertical-center {
        align-items: center;
    }
}

.u__margin {
    &--bottom {
        margin-bottom: 15px ;
    }
}

.u__separator {
   border-bottom: 1px solid #CCCCCC;
   margin: 0 auto;
}

.u__flex {
   display: flex;
}

.u__flex {
    gap: calc(var.$grid-gap / 2) ;
 }
 .u__flex--fullrow {
    flex: 1 100%;
 }

  .u__flex--halfrow {
      flex-grow: 1;
  }

  .u__form--100 {
    width: 100px;
  }
  .u__form--150 {
    width: 150px;
  }
  .u__form--200 {
    width: 200px;
  }
  .u__form--300 {
    width: 300px;
  }
  .u__form--400 {
    width: 400px;
  }
 .u__form-row {
    gap: var(--grid-gap);
    display: flex;
    width: 100%;
 }
 .u__m-b-g {
  margin-bottom: var(--grid-gap);
 }

 .u__bg--primary-dirigent {
    background-color: var(--primary-dirigent);
   }

.u__v-mobile-hidden {
    @media only screen and (max-width: var.$mobile-portrait) {
        display: none;
    }
}

.u__up-v-mobile-hidden {
    @media only screen and (min-width: var.$mobile-portrait) {
        display: none;
    }
}