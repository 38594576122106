h1 {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--primary);
}

h2 {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--primary);
}


h3 {
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--primary);
}
h4 {
  font-weight: 700;
}

p {
  color: var(--primary);
}

.rubik-300 {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight:300;
  font-style: normal;
}