
@use "../variables.scss" as var;
@use "../theme/is-theme" as theme;

mat-drawer-container, mat-drawer-content {
    display: block; 
    height: 100%; 
}
mat-drawer.mat-drawer {
  background-color: transparent;
}

.mat-drawer-container {
  height: calc(100vh - var.$top-bar-height);
  background-color: transparent;
}
.mat-drawer {
    width: 230px;
    background-color: transparent;
}
.mat-drawer-content {
    height: 100%;
    max-height: 100%;
    border-top-left-radius: theme.$border-radius;
    border-bottom-left-radius: theme.$border-radius;
    background-color: #e3e0e6;

    @media only screen and (max-width: 600px) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
}
