@use '@angular/material' as mat;

.mat-elevation-z8 {
    @include mat.elevation(8, #999, 0.3);
}

.mat-elevation-z2 {
    @include mat.elevation(2, #999, 0.5);
}

.mat-elevation-z3 {
    @include mat.elevation(3, #999, 0.3);
}