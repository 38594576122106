
@use "../variables.scss" as var;
@use "../theme/is-theme" as theme;

.mat-chip.mat-standard-chip {
    white-space: nowrap;
    &.mat-chip-selected {
        &.mat-chip--green {
            background-color:#B3F5AF;
            color: theme.$grey--dark;
        }
        &.mat-chip--primary {
            background-color: theme.$primary;
            
        }
        &.mat-chip--accent {
            background-color: theme.$accent;
            color: theme.$grey--dark;
        }
        &.mat-chip--red {
            background-color: theme.$red-200;
        }
    }
}
