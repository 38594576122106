@use "../theme/is-theme" as theme;
@use '@angular/material' as mat;

.mat-mdc-mini-fab {

    &.mat-mdc-mini-fab--small {
        height: 32px;
        width: 32px;
        vertical-align: middle;
        .mat-icon {
            font-size: 16px;
            height: 18px;
            width: 18px;
            margin-top: 3px;
        }
        .mat-mdc-button-wrapper {
            padding: 4px 0;
        }
    }
    &.mat-mdc-mini-fab--white {
      background-color: white;
    }
    &.mat-accent .mat-icon {
        color: white;
    }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    &.mat-mdc-icon-button--small {
        height: 32px;
        width: 32px;
        vertical-align: middle;
        padding: 5px;
        .mat-icon {
            font-size: 16px;
            height: 18px;
            width: 18px;
            margin-top: 3px;
        }
        .mat-mdc-button-wrapper {
            padding: 4px 0;
        }
        
    }

    &.mat-mdc-icon-button--clean {
        .mat-mdc-button-persistent-ripple {
            display: none;
        }
        
    }
}

//ALL BUTTONS
.mat-mdc-raised-button, .mat-mdc-outlined-button,  .mat-mdc-button  {
    height: 42px;
    &.mat-mdc-button-base {
        height:42px;
        border-radius: theme.$border-radius--medium;
    }
    &.mat-mdc-raised-button--small {
        &.mat-mdc-button-base {
            height: 32px;
        }
        .mdc-button__label {
            font-size: 12px;
        }
    }
}

//RAISED BUTTON
.mat-mdc-raised-button, .mat-mdc-button  {
 
    &.mat-mdc-raised-button--shadow {
      @include mat.elevation(3, theme.$primary, 1);
      &:hover {
        @include mat.elevation(7, theme.$primary, 1);
      }
    }
     .mat-icon[matsuffix] {
        margin-left: 10px;
    }

    &--white {
        background-color: white;
        
    }
}

