
.dialog {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__close {
        &.mdc-icon-button {
            --mat-mdc-button-ripple-color: transparent;
            position: absolute;
            top: 10px;
            right: 15px;
            .mat-mdc-button-persistent-ripple {
                display: none;
            }
            .mat-icon {
                height: 30px;
                width: 30px;
                font-size: 30px;
            }
        }
    }
    &__title, .mdc-dialog__title {
        padding: var(--grid-gap);
        display: flex;
        align-items: center;
        h2 {
            font-size: 20px;
            font-weight: 500;
            display: inline-block;
            margin: 10px 0;
        }
    }
    &__content, &__content.mdc-dialog__content.mat-mdc-dialog-content {
        flex: 1;
        padding: 0 var(--grid-gap);
        padding-bottom: 0;
        >h3 {
            margin: 0 0 10px 0;
        }
      
    }
    &__footer, &__actions, .mat-mdc-dialog-actions.mdc-dialog__actions {
        display: flex;
        align-items: center;
        min-height: 80px;
        width: 100%;
        box-sizing: border-box;
        padding: var(--grid-gap);
        justify-content: space-between;
        gap: var(--grid-gap);
        &--reverse {
            justify-content: space-between;
            flex-direction: row-reverse;
        }
        .mat-mdc-button-base+.mat-mdc-button-base {
            margin-left: 0;
        }
        &__group {
            display: flex;
            align-items: center;
            gap: var(--grid-gap);
        }
    } 
    &__stepper-footer {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        gap: var(--grid-gap);
    } 
    &--small {
        padding:var(--grid-gap);
        box-sizing: border-box;
        .dialog {
            &__message {
                flex: 1;
            }
            &__footer {
                padding: 0;
                min-height: 0;
            }
            &__content {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}