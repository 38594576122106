
@use "../variables.scss" as var;
@use "../theme/is-theme" as theme;

.mat-mdc-table {
  
    &--fullwidth {
        width: 100%;
    }
    .mat-mdc-cell {
        font-size: 14px;
        padding: 0 5px;
        > h3 {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            margin: 0;
        }
        > span {
            color: theme.$grey;
        }
    }
    .mat-mdc-header-cell {
        text-align: left;
        font-size: 12px;
        color: black;
        font-weight: 600;
    }
    .mdc-data-table__cell, .mdc-data-table__header-cell {
        border-bottom-color: theme.$grey--lightest;
        padding: 5px 8px;
    }
    .mat-mdc-sort-header-container {
        justify-content: flex-start;
    }
}