@use "../variables.scss" as var;
@use "../theme/is-theme" as theme;

.card {
    &.mat-mdc-card {
      border-radius: theme.$border-radius--medium;
    }
    padding: var(--grid-gap);
   
    &__footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 60px;
        box-sizing: border-box;
        padding: 0;
        padding-top: var(--grid-gap);
        &--reverse {
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }
    &__placeholder {
        background-color: grey;
        border: 2px dashed black;
    }
    &--no-shadow {
      &.mat-mdc-card {
        box-shadow: none;
      }
    }
    &--soft-shadow {
      &.mat-mdc-card {
        -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.10);
        -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.10);
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.10);
      }
    }
}