@use 'sass:map';
@use '@angular/material' as mat;
@use "@material/button";
@use "@material/shape";

@include mat.core();


$mediacloud-primary-pallete: (
    50 : #e3e0e6,
    100 : #b8b3c0,
    200 : #898097,
    300 : #5a4d6d,
    400 : #36264d,
    500 : #13002e,
    600 : #110029,
    700 : #0e0023,
    800 : #0b001d,
    900 : #060012,
    A100 : #6d53ff,
    A200 : #4120ff,
    A400 : #2300ec,
    A700 : #1f00d3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);



$dirigent-primary-pallete: (
  50 : #e7eefa,
  100 : #c4d4f2,
  200 : #9db7e9,
  300 : #769ae0,
  400 : #5884d9,
  500 : #3b6ed2,
  600 : #3566cd,
  700 : #2d5bc7,
  800 : #2651c1,
  900 : #193fb6,
  A100 : #eaeeff,
  A200 : #b7c6ff,
  A400 : #849eff,
  A700 : #6a8aff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$ismedia-accent-pallete: (
    50 : #e0f8f5,
    100 : #b3efe6,
    200 : #80e4d5,
    300 : #4dd9c4,
    400 : #26d0b7,
    500 : #00c8aa,
    600 : #00c2a3,
    700 : #00bb99,
    800 : #00b490,
    900 : #00a77f,
    A100 : #d1fff2,
    A200 : #9effe4,
    A400 : #6bffd6,
    A700 : #52ffcf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$ismedia-red-pallete: (
    50 : #f9e0e0,
    100 : #f0b3b3,
    200 : #e68080,
    300 : #db4d4d,
    400 : #d42626,
    500 : #cc0000,
    600 : #c70000,
    700 : #c00000,
    800 : #b90000,
    900 : #ad0000,
    A100 : #ffd7d7,
    A200 : #ffa4a4,
    A400 : #ff7171,
    A700 : #ff5858,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$ismedia-yellow-pallete:  (
    50 : #fffaed,
    100 : #fff4d1,
    200 : #ffecb3,
    300 : #ffe494,
    400 : #ffdf7d,
    500 : #ffd966,
    600 : #ffd55e,
    700 : #ffcf53,
    800 : #ffca49,
    900 : #ffc038,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff5e1,
    A700 : #ffedc8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$ismedia-grey-pallete: (
    50 : #efefef,
    100 : #d8d8d8,
    200 : #bebebe,
    300 : #a3a3a3,
    400 : #909090,
    500 : #7c7c7c,
    600 : #747474,
    700 : #696969,
    800 : #5f5f5f,
    900 : #4c4c4c,
    A100 : #f7baba,
    A200 : #f28c8c,
    A400 : #ff4d4d,
    A700 : #ff3333,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$ismedia-dark-grey-pallete: (
    50 : #e7e7e7,
    100 : #c3c3c3,
    200 : #9b9b9b,
    300 : #727272,
    400 : #545454,
    500 : #363636,
    600 : #303030,
    700 : #292929,
    800 : #222222,
    900 : #161616,
    A100 : #ef6f6f,
    A200 : #ea4141,
    A400 : #f80000,
    A700 : #df0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


$ismedia-typography-config: mat.define-typography-config(
    $font-family: 'Roboto, sans-serif',
    $headline-1: mat.define-typography-level("Rubik", 112px, 1.4, 400, $letter-spacing: normal),
    $headline-2: mat.define-typography-level("Rubik", 20px, 1.4, 400, $letter-spacing: normal),
    $headline-3: mat.define-typography-level("Rubik", 18px, 1.4, 400, $letter-spacing: normal),
    $headline-4: mat.define-typography-level("Rubik", 34px, 1.4, 400, $letter-spacing: normal),
    $headline-5: mat.define-typography-level("Rubik", 24px, 1.4, 400, $letter-spacing: normal),
    $body-1: mat.define-typography-level(16px, 1.4, 500, $letter-spacing: normal),
    $button: mat.define-typography-level(14px, 1.4, 500, $letter-spacing: 1.1px)
);


// Create the theme object (a Sass map containing all of the palettes).

$isDynamicPlanner-theme: mat.define-light-theme((
 color: (
   primary:  mat.define-palette($mediacloud-primary-pallete),
   accent: mat.define-palette($ismedia-accent-pallete),
   warn: mat.define-palette($ismedia-red-pallete),
 ),
 typography: $ismedia-typography-config,
 density: 0,
));


$primary: mat.get-color-from-palette($mediacloud-primary-pallete, 500);
$primary-light:  mat.get-color-from-palette($mediacloud-primary-pallete, 100);
$primary-dark:  mat.get-color-from-palette($mediacloud-primary-pallete, 900);

$primary-50:  mat.get-color-from-palette($mediacloud-primary-pallete, 50);


$primary-dirigent: mat.get-color-from-palette($dirigent-primary-pallete, 500);
$primary-dirigent-light:  mat.get-color-from-palette($dirigent-primary-pallete, 100);
$primary-dirigent-dark:  mat.get-color-from-palette($dirigent-primary-pallete, 900);


$accent: mat.get-color-from-palette($ismedia-accent-pallete, 500);
$accent-50: mat.get-color-from-palette($ismedia-accent-pallete, 50);

$red: mat.get-color-from-palette($ismedia-red-pallete, 500);
$red-200: mat.get-color-from-palette($ismedia-red-pallete, 200);

$yellow: mat.get-color-from-palette($ismedia-yellow-pallete, 500);
$green: $accent;


$grey: mat.get-color-from-palette($ismedia-grey-pallete, 500);
$grey--very-light: mat.get-color-from-palette($ismedia-grey-pallete, 10);
$grey--lightest: mat.get-color-from-palette($ismedia-grey-pallete, 50);
$grey--lighter: mat.get-color-from-palette($ismedia-grey-pallete, 100);
$grey--light: mat.get-color-from-palette($ismedia-grey-pallete, 200);
$grey--dark: mat.get-color-from-palette($ismedia-grey-pallete, 900);


$grey-background: #ecf0fb;
$border-radius: 30px !default;
$border-radius--medium: 20px !default;

$font-family: mat.font-family($ismedia-typography-config);

@include mat.core-theme($isDynamicPlanner-theme);
@include mat.all-component-themes($isDynamicPlanner-theme);
@include mat.strong-focus-indicators-theme($isDynamicPlanner-theme);



.mat-mdc-dialog-container {
  border-radius: 20px;
}


.mat-color-yellow {
  color: $yellow;
}

.mat-color-red {
    color: $red-200;
  }