  .pictogram {
    display: flex;
    gap: var(--grid-gap);
    align-items: center;

    &__image, svg {
        width: 200px;
      }
    &__text {
        max-width: 400px;
        color: rgba(0,0,0,0.6);
        font-size: 14px;
        h3 {
          font-size: 22px;
        }
    }
    &__action {
    }
    &--vcentered {
        align-items: center;
        height: 100%;
    }
    &--hcentered {
      width: 100%;
      justify-content: center;
    }

    &--primary-color {
      background-color: var(--primary);
    }
     &--primary {
      fill:var(--primary);
    }
    &--vertical {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 0;
    }
    &--opacity {
      opacity: 0.5;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

  }