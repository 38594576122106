.is-ui {
    &.is-ui--white {
        &.mat-mdc-menu-trigger {
            span, .mat-icon  {
                color: white;
            }
        }
    }
    &.mat-mdc-icon-button.mat-mdc-button-base {
        padding: 0;
        width: 36px;
        height: 36px;
    }
}