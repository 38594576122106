@use '../../../assets/scss/variables' as var;

.login-screen {
    justify-content: center;
    align-items: center;
    height: 100vh;
    //background-color: var(--primary);
    background-color: transparent;
    align-items: stretch;
    h2 {
      font-size: 26px;
      margin-bottom: 10px;
    }
    p {
      display: inline-block;
      font-size: 16px;
      margin-bottom: var(--grid-gap);
    }
    &__confirmation {
      margin-bottom: 15px;
    }
    &__illustration {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      app-login-illustration {
        height: 100%;
      }
      @media only screen and (max-width: 600px) {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    &__input {
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  
    &__forms {
      position: absolute;
      right: 0;
      z-index: 9;
      padding: var(--grid-gap);
      display:flex;
      flex: 1;
      border-top-left-radius: var(--default-border-radius);
      border-bottom-left-radius: var(--default-border-radius);
      background-color: white;
      height: 100%;
      width: 50%;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 600px) {
        flex: inherit;
        flex-direction: column;
        z-index: 9;
        padding-top: 50px;
        padding-bottom: 50px;
        max-width: 580px;
        width: 95%;
        min-height: 100%;
        height: auto;
        overflow-y: auto;
        border-top-right-radius:  0;
        border-bottom-right-radius:  0;

      }
    }
    &__divider {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        height: 1px;
        flex:1;
        background-color: var(--grey--lighter);
      }
      > p {
        padding: 0 var(--grid-gap);
        margin-bottom: 0px !important;
      }
    }
    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      img {
          max-width: 150px;
         
      }
    }
    &__row {
      display: flex;
      gap: var(--grid-gap);
      @media only screen and (max-width: 600px) {
        flex-direction: column; 
        gap: 0;
        .mat-mdc-form-field--half {
          width: 100%;
        }
      }
    }
    &__container {
        max-width: 450px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:  10px;
        @media only screen and (max-width: 600px) {
          gap: 0px;
        }
        
    }
    &__actions {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__message {
      max-width: 400px;
      margin: 0 auto;
        p {
          display: inline-block;
          font-size: 16px;
          margin-bottom: var(--grid-gap);
        }
    }
    &__registration {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__error {
      font-size: 11px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 20px;
      font-weight: 500;
    }
    &__response-status {
      display: flex;
      flex-direction: column;
    }
    &__contact {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      @media only screen and (max-width: 600px) {
        position: relative;
      }
    }
    @media only screen and (max-width: 600px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: auto;
      padding-top: 0px;
      padding-bottom: 0px;
      height: auto;
      min-height: 100%;
    }
}