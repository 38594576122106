
.is-ui {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0 3px;
        .mat-mdc-form-field-infix {
            padding-top: 20px;
        }
        .mat-mdc-form-field-flex .mat-mdc-floating-label{
            top: 25px
        }
      }
      .mat-mdc-input-element {
        font-size: 14px;
      }
   
      .mat-mdc-form-field-infix {
        min-height: 45px;
        padding-top:10px ;
        padding-bottom: 5px;
        height: 45px;
      }
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
      }
      .mat-mdc-floating-label.mdc-floating-label {
        font-size: 12px;
      }
    }
    &--color {
        &.mat-mdc-form-field {
            width: 70px;
            .mat-mdc-form-field-underline  {
                display: none;
            }
            .mat-mdc-form-field-infix {
                padding: 0;
            
            
            }
            input[type=color] {
                height: 33px;
                width: 33px;
            }
        }
    }

    &--small {
        &.mat-mdc-form-field {
            font-size: 0.74rem;
        }
    }
    &--fullwidth {
        width: 100%;
        .mat-mdc-form-field-infix {
            width: 100%;
        }
    }
    &--white {
        .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input{
            color: white;
        }
        .mat-mdc-form-field-focus-overlay {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before  {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
           border: rgba(255, 255, 255, 0.5);
        }
    }
    &--clean {
        .mat-mdc-form-field-label {
            display: none !important;
        }
    }
    &--xs-width {
        width: 60px;
    }
}