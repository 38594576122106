.mat-step-label {
    .mat-step-label-title {
        font-size: 16px;
        font-weight: 400;
    }
    .mat-step-label-subtitle {
        font-size: 12px;
        font-weight: 400;
    }
}

.mat-step-header {
    border-radius: var(--border-radius--medium);
}