.is-ui {
    &.mat-mdc-select {
        .mat-mdc-select-value-text {
            font-size: 14px;
        }
    }
    .mat-mdc-option {
        font-size: 14px;
        min-height: 36px;
    }
} 