
@use "variables.scss" as var;
@use "theme/is-theme" as theme;

:root {
    --font-family: #{theme.$font-family};
    --grid-gap: #{var.$grid-gap};
    --grid-gap--small: #{var.$grid-gap--small};
    --primary: #{theme.$primary};
    --primary-hover--light: #{theme.$primary-light};
    --primary-hover--dark: #{theme.$primary-dark};
    --primary-hover: #{darken(theme.$primary, 10%)};
    --primary-dirigent: #{theme.$primary-dirigent};
    --primary-dirigent-light: #{theme.$primary-dirigent-light};

    --accent: #{theme.$accent};
    --yellow: #{theme.$yellow};
    --green: #{theme.$green};
    --red: #{theme.$red};
    --grey: #{theme.$grey};
    --grey--light: #{theme.$grey--light};
    --grey--lightest: #{theme.$grey--lightest};
    --grey--lighter: #{theme.$grey--lighter};
    --grey-background: #{theme.$grey-background};
    --default-border-radius: #{theme.$border-radius};
    --border-radius--medium: #{theme.$border-radius--medium};
    --border-radius--small: calc(#{theme.$border-radius--medium} -  #{var.$grid-gap--small});
    --top-bar-height: #{var.$top-bar-height};
    --mdc-filled-text-field-container-color:  #{theme.$grey-background};
    --mdc-switch-unselected-track-color: #{theme.$grey-background};
}

html, body { height: 100%; }
body { 
    margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; 
}
main { height: 100%; overflow-y: auto; }


a {
    color:  var(--primary);
    &.mail-link {
        text-decoration: underline;
    }
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
    background-color: var(--grey--lightest);
    border-radius: 4px;
    
}

::-webkit-scrollbar
{
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb
{
    background-color: var(--grey--lighter);
    border-radius: 4px;
    &:hover {
        transition: background-color 0.4s ease-in-out;
        background-color: var(--grey-backround);
    }
}

.clearfix {
    clear: both;
}