.is-ui.mat-slide-toggle {
   .mat-slide-toggle-bar  {
        height: 10px;
   } 
   .mat-slide-toggle-thumb-container {
       top: -4px;
       left: 0px;
   }
   .mat-slide-toggle-content {
       font-size: 15px;
   }
   .mat-slide-toggle-thumb {
        height: 18px;
        width: 18px;
   }
}