.mat-mdc-dialog-container {
    position: relative;
    height: auto;

    .mat-mdc-dialog-actions {
        //padding: 20px 0;
        min-height: 80px;
        box-sizing: border-box;
    }
    .mdc-dialog__surface {
      border-radius: var(--default-border-radius) !important;
    }
  
}
