@use "../variables.scss" as var;
@use "../theme/is-theme" as theme;

.mat-mdc-form-field {
  &.mat-mdc-form-field {
    .mdc-notched-outline__notch {
      border-right: 0px solid transparent;
    }
  }

 
  &--fullwidth {
      width: 100%;
  }
  &--half {
      width: 50%;
  }
  &--small {
      width: 100%;
      .mat-mdc-form-field-infix {
          width: auto;
      }
      input[type=color] {
          height: 19px;
          max-width: 40px;
      }
  }
  &--nohint {
    .mat-mdc-form-field-subscript-wrapper  {
      display: none;
    }
  }
  &--white {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: white !important;
      &::placeholder {
        opacity: 0.5;
        color: white !important;
      }
    }
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch{
      border-color: white !important;
    }
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading  {
      border-color: white !important;
    }
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: white !important;
    }
  }
  &--rounded {
    .mdc-text-field {
      border-top-left-radius: theme.$border-radius;
      border-top-right-radius: theme.$border-radius;
    }
    .mdc-text-field--outlined .mdc-notched-outline__leading  {
      border-top-left-radius: theme.$border-radius--medium !important;
      border-bottom-left-radius: theme.$border-radius--medium !important;
      min-width: 20px !important;
    }
    .mdc-text-field--outlined .mdc-notched-outline__trailing {
      border-top-right-radius: theme.$border-radius--medium !important;
      border-bottom-right-radius: theme.$border-radius--medium !important;
      min-width: 20px !important;
    }
    
  }
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

.mdc-notched-outline__leading {
  border-top-left-radius: theme.$border-radius--medium !important;
  border-bottom-left-radius: theme.$border-radius--medium !important;
}

.mdc-notched-outline__trailing {
  border-top-right-radius: theme.$border-radius--medium !important;
  border-bottom-right-radius: theme.$border-radius--medium !important;
}