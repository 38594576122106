.formated-text {
    max-width: 800px;
    margin: 0 auto;
    h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-weight: 700;
    }

    ul {
        margin-bottom: 20px;
    }

    li, p {
        margin-bottom: 20px;
    }

    .highligted {
        background-color: rgb(255, 248, 151);
    }
}